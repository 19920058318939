import { useDisclosure, Avatar, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Box, Flex, Heading, Image, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
import React from 'react';
import { Member } from '../Model/Member';

type Props = {
    member: Member;
}

const UserAvatar: React.FC<Props> = ({member}) => {

    // i18n Transration
    const { t } = useTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Avatar src={member.currentPicture} onClick={() => onOpen()} />
            <Modal isCentered isOpen={isOpen} onClose={onClose} size="6xl" scrollBehavior="inside">
                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
                <ModalContent p="10">
                    <ModalHeader>
                        <Flex flex='1' gap='6' alignItems='center' flexWrap='wrap'>
                            <Avatar name={member.name} src={member.currentPicture} size="xl" />
                            <Box>
                                <Heading fontSize='4xl'>{member.name}</Heading>

                                <Text fontSize='xl' color="grey" pt='4'>
                                    {(member.detect ? t("screen.text.members.working") : t("screen.text.members.not_working")) +
                                    "　-　" + 
                                    (member.loggined ? t("screen.text.members.loggined") : t("screen.text.members.not_loggined"))}
                                </Text>
                                <Text fontSize='xl' color="grey" pt='2'>{member.secret?.currentApp}</Text>
                                <Text fontSize='xs' color="grey" pt='2' >{member.updatedAt + ''}</Text>
                            </Box>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        { member.secret?.currentDesktopCaptures ? (
                        <Wrap spacing='24px' mt="4">
                            {member.secret?.currentDesktopCaptures?.map(capture => {
                                return (
                                    <WrapItem key={capture} w={"full"}>
                                        <Image src={capture} htmlWidth="100%" />
                                    </WrapItem>
                                )
                            })}
                        </Wrap>
                        ) : (
                            <Text color="grey">No Desktop Capture Image</Text>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
      )
};

export default UserAvatar;