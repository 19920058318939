import { FirestoreDataConverter, serverTimestamp } from "firebase/firestore";
import { MemberSecret } from "./MemberSecret";

export type Member = {
    id?: string;
    name: string;
    email: string;
    teamId:string;
    detect?: boolean;
    enableCamera?: boolean;
    loggined?: boolean;
    icon?: string;
    message? :string;
    currentPicture?: string;
    clientVersion?: string;
    lastDetectedAt?: string;
    updatedAt?: Date;
    secret?:MemberSecret;

}

export const MemberConverter: FirestoreDataConverter<Member> = {
  // 管理アプリから更新する項目だけを対象とする
  toFirestore: (member) => {
    return {
      name: member.name,
      email: member.email,
      teamId: member.teamId,
      updatedAt: serverTimestamp(),
    };
  },
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    const member = {
      id: snapshot.id,
      ...data,
      updatedAt: data.updatedAt?.toDate(),
    } as Member;
    return member;
  },
};

