import { useRecoilCallback } from 'recoil';
import { User } from '@firebase/auth-types';
import { updateEmail, updatePassword } from "firebase/auth";

export const useUserHook = () => {

  const updateEmailAddress = useRecoilCallback(({ set }) => async (authUser:User, email: string) => {
    console.log('UserHook updateEmailAddress start');

    await updateEmail(authUser, email);

    console.log('UserHook updateEmailAddress end');
  });

  const updateCurrentPassword = useRecoilCallback(({ set }) => async (authUser:User, newPassword: string) => {
    console.log('UserHook updateCurrentPassword start');

    await updatePassword(authUser, newPassword);

    console.log('UserHook updateCurrentPassword end');
  });

  return {
    updateEmailAddress,
    updateCurrentPassword,
  }
}
