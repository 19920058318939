import { AttachmentIcon } from '@chakra-ui/icons';
import { InputGroup, Input, InputRightElement, IconButton, useToast } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

type Props = {
    copyText: string;
}

const CopyToClipboardForm: React.FC<Props> = ({copyText}) => {

    const toast = useToast();

    // i18n Transration
    const { t } = useTranslation();

    const handleCopy = (): void => {
        toast({
            title: t("message.text.action.copied"),
            status: 'info',
            duration: 2000,
          })
    };

    return (
    <InputGroup size='md'>
        <Input type='text' value={copyText} readOnly/>

        <InputRightElement width='2.3rem'>
            <CopyToClipboard text={copyText} onCopy={handleCopy}>
                <IconButton disabled={copyText === ''} aria-label=''>
                    <AttachmentIcon/>
                </IconButton>
            </CopyToClipboard>
        </InputRightElement>

    </InputGroup>
    );
};

export default CopyToClipboardForm;
