import { collection, getDocs } from '@firebase/firestore';
import { useRecoilCallback } from 'recoil';
import { firestore } from '../firebase';
import { User } from '@firebase/auth-types';
import { Member } from '../Model/Member';
import { workReportsState } from '../State/WorkReportsState';
import { WorkReportConverter } from '../Model/WorkReport';
import * as idb from 'idb-keyval';
import dayjs from '../dayjs';

export const useWorkReportsHook = () => {


  const fetchCurrentWorkReports = useRecoilCallback(({ snapshot, set }) => async (authUser: User, targetMember: Member, targetDates: Date[]) => {

    console.log('MembersHook fetchMemberWorkReports start');

    set(workReportsState, []);

    // 指定日数分の Work Report を取得する
    const workReports =  await Promise.all(targetDates.map(async targetDate =>  {

      const yyyyMMdd = targetDate.toISOString().replace(/-/g, '').slice(0, 8);

      // Cacheに存在するデータである場合はCacheを利用する
      const cacheKey = `${targetMember.id}:${yyyyMMdd}`;
      const cachedData = await idb.get(cacheKey);
      if (cachedData) return cachedData;

      const workReporttRef = collection(firestore, `/update_histories/${authUser.uid}/members/${targetMember.id}/daily/${yyyyMMdd}/every`).withConverter(WorkReportConverter);
      const workReportSnapshots = await getDocs(workReporttRef);

      const returnData = {key: yyyyMMdd, value: workReportSnapshots.docs.map((snapshot) => snapshot.data())};
      // 今日のデータ以外はCacheに保存する(=今日のデータは変更される可能性があるため、Cacheには保存しない)
      if(yyyyMMdd !== dayjs.tz(dayjs(), 'UTC').format("YYYYMMDD")) await idb.set(cacheKey, returnData);
      return returnData;

    }))

    // Promiseで順番は変わらないため、再ソートは不要
    // const sortedWorkReports = workReports.sort((a, b) => ((a.key > b.key) ? -1 : 1));

    set(workReportsState, workReports);
    console.log('MembersHook fetchMemberWorkReports end');

  });


  return {
    fetchCurrentWorkReports,
  }
}
