import { collection, getDocs } from '@firebase/firestore';
import { httpsCallable } from "firebase/functions";
import { useRecoilCallback } from 'recoil';
import { firestore, functions } from '../firebase';
import { User } from '@firebase/auth-types';
import { Member, MemberConverter } from '../Model/Member';
import { membersState } from '../State/MembersState';
// import CryptoJS from "crypto-js";
import {v4 as uuidv4} from 'uuid';
import { doc, runTransaction } from 'firebase/firestore';
import { MemberSecretConverter } from '../Model/MemberSecret';

export const useMembersHook = () => {

  const fetchMembers = useRecoilCallback(({ set }) => async (authUser: User) => {

    console.log('MembersHook fetchMembers start');

    // Secretを取得しておく
    const membersSecretRef = collection(firestore, `/users/${authUser.uid}/membersSecret`).withConverter(MemberSecretConverter);
    const membersSecretSnapshots = await getDocs(membersSecretRef);
    const membersSecret = membersSecretSnapshots.docs.map((snapshot) => snapshot.data());

    // メンバー情報を取得して、同一IDを持つSecretを紐づける
    const membersRef = collection(firestore, `/users/${authUser.uid}/members`).withConverter(MemberConverter);
    const membersSnapshots = await getDocs(membersRef);
    const members = membersSnapshots.docs.map((snapshot) => {
      let member = snapshot.data();
      member.secret = membersSecret.find((secret) => secret.id === member.id);
      return member;
    });

    set(membersState, members);
  
    console.log('MembersHook fetchMembers end');

  });
  

  const addMember = useRecoilCallback(({ set }) => async (authUser:User, data:Member) => {

    console.log('MembersHook addMember start');

    if (!authUser) return;
    if (!data) return;

    // ユーザーIDを生成する
    const memberUUID = uuidv4();

    const memberRef = doc(firestore, `/users/${authUser.uid}/members/${memberUUID}`).withConverter(MemberConverter);

    try {
      await runTransaction(firestore, async (transaction) => {

        transaction.set(memberRef, MemberConverter.toFirestore(data), {merge: true});

      });
    } catch (e) {
      console.log("Transaction failed: ", e);
    }

    await fetchMembers(authUser);

    console.log('MembersHook addMember end');
  });

  
  const updateMember = useRecoilCallback(({ set }) => async (authUser:User, data:Member) => {

    console.log('MembersHook updateMember start');

    if (!authUser) return;
    if (!data) return;

    const memberRef = doc(firestore, `/users/${authUser.uid}/members/${data.id}`).withConverter(MemberConverter);

    try {
      await runTransaction(firestore, async (transaction) => {

        transaction.set(memberRef, MemberConverter.toFirestore(data), {merge: true});

      });
    } catch (e) {
      console.log("Transaction failed: ", e);
    }

    await fetchMembers(authUser);

    console.log('MembersHook updateMember end');
  });

  const deleteMember = useRecoilCallback(({ set }) => async (authUser:User, data:Member) => {

    console.log('MembersHook deleteMember start');

    if (!authUser) return;
    if (!data) return;

    const memberRef = doc(firestore, `/users/${authUser.uid}/members/${data.id}`).withConverter(MemberConverter);
    const memberSecretRef = doc(firestore, `/users/${authUser.uid}/membersSecret/${data.id}`).withConverter(MemberSecretConverter);

    try {
      await runTransaction(firestore, async (transaction) => {

        transaction.delete(memberRef);
        transaction.delete(memberSecretRef);

      });
    } catch (e) {
      console.log("Transaction failed: ", e);
    }

    await fetchMembers(authUser);

    console.log('MembersHook deleteMember end');
  });

  const generateAccessToken = useRecoilCallback(({ set }) => async (data:Member, expireAccessToken:string = "") => {

    console.log('MembersHook generateAccessToken start');

    const generateAccessTokenCallable = httpsCallable(functions, 'generateAccessToken');
    const result:any = await generateAccessTokenCallable({ teamId: data.teamId, userId: data.id, expireAccessToken: expireAccessToken });

    console.log('MembersHook generateAccessToken end');
    return result.data.accessToken;
  });

  const sendMailForInviteTeam = useRecoilCallback(({ set }) => async (data:Member, accessToken:string) => {

    console.log('MembersHook sendMail start');

    const sendMailForInviteTeamCallable = httpsCallable(functions, 'sendMailForInviteTeam');
    await sendMailForInviteTeamCallable({ email: data.email, name: data.name, accessToken: accessToken });

    console.log('MembersHook sendMail end');
  });


  return {
    fetchMembers,
    addMember,
    updateMember,
    deleteMember,
    generateAccessToken,
    sendMailForInviteTeam,
  }
}
