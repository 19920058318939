import { httpsCallable } from "firebase/functions";
import { useRecoilCallback } from 'recoil';
import { functions } from '../firebase';

export const useContactHook = () => {

  const sendContactMail = useRecoilCallback(({ set }) => async (title:string, body:string) => {

    console.log('ContactHook sendContactMail start');

    const sendContactMailCallable = httpsCallable(functions, 'sendContactMail');
    await sendContactMailCallable({ title: title, body: body });

    console.log('ContactHook sendContactMail end');
  });


  return {
    sendContactMail,
  }
}
