import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { authState } from "../State/AuthState";
import { z } from "zod";
import Loading from "../Component/Loading";
import { useUserHook } from "../Hook/UserHook";
import { Box, Button, Heading, Input, Text, useToast } from '@chakra-ui/react';
import { useSettingHook } from "../Hook/SettingHook";
import { settingState } from "../State/SettingState";
import { useTranslation } from "react-i18next";


/*******************************************************************
 * 設定コンポーネント
 *******************************************************************/
 export const Settings = () => {

  // Hooks ******************************************
  const authUser = useRecoilValue(authState);   // 現在のログインユーザー
  const useUser = useUserHook();
  const useSetting = useSettingHook();
  const toast = useToast();

    // i18n Transration
    const { t } = useTranslation();

  // Recoil State ******************************************
  const [integrationSettingForm, setIntegrationSettingForm] = useRecoilState(settingState); // 全体に関する設定情報


  // 設定に関する初期情報 ******************************************
  const initialForm = {
    email: authUser?.email || "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [userAuthSettingForm, setUserAuthSettingForm] = useState(initialForm);
  
  const [emailError, setEmailError] = useState<string|null>(null);
  const [currentPasswordError, setCurrentPasswordError] = useState<string|null>(null);
  const [newPasswordError, setNewPasswordError] = useState<string|null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<string|null>(null);


  useEffect(() => {
    if(!authUser) return;
    useSetting.fetchSetting(authUser);
    // eslint-disable-next-line
  }, [authUser]); 


  // ユーザー認証情報フォームの値更新イベント
  const handleUserAuthFormChanges = (input:string) => (e: { target: { value: string; }; }) => {
    setUserAuthSettingForm({...userAuthSettingForm, [input] : e.target.value});
  };

  // 外部連携情報フォームの値更新イベント
  const handleIntegrationFormChanges = (input:string) => (e: { target: { value: string; }; }) => {
    setIntegrationSettingForm({...integrationSettingForm, [input] : e.target.value});
  };
  
  // Eメール更新イベント
  const handleUpdateEmail = async () => {

    setEmailError(null);

    if (!authUser) return;

    const FormValidator = z.object({
      email: z.string()
        .min(1, { message: t("message.text.validation.required_email")! })
        .email({ message: t("message.text.validation.invalid_email")! })
    })

    try {
      FormValidator.parse(userAuthSettingForm);
      await useUser.updateEmailAddress(authUser, userAuthSettingForm.email!);

      toast({
        title: t("message.text.action.update_mail_success"),
        status: 'success',
        isClosable: true,
        duration: 5000,
      })

    } catch(err) {
      if (err instanceof z.ZodError) {
        const flatErrorInfo = err.flatten()
        if(flatErrorInfo.fieldErrors["email"]) setEmailError(flatErrorInfo.fieldErrors["email"][0]);
      }
    }
  }

  // パスワード更新イベント
  const handleUpdatePassword = async () => {

    setCurrentPasswordError(null);
    setNewPasswordError(null);
    setConfirmPasswordError(null);

    if (!authUser) return;

    const FormValidator = z.object({
      currentPassword: z.string()
        .min(1, { message: t("message.text.validation.invalid_current_password")! }),
      newPassword: z.string()
        .min(1, { message: t("message.text.validation.invalid_new_password")! }),
      confirmPassword: z.string()
        .min(1, { message: t("message.text.validation.invalid_confirm_password")! })
    })

    try {
      FormValidator.parse(userAuthSettingForm);

      // 現在のパスワードの一致確認
      if (userAuthSettingForm.currentPassword !== authUser.email) {
        setCurrentPasswordError(t("message.text.validation.invalid_current_password")!);
        return;
      }

      // 確認用パスワードの一致確認
      if (userAuthSettingForm.newPassword !== userAuthSettingForm.confirmPassword) {
        setConfirmPasswordError(t("message.text.validation.invalid_confirm_password")!);
        return;
      }

      await useUser.updateCurrentPassword(authUser, userAuthSettingForm.newPassword!);

      toast({
        title: t("message.text.action.update_password_success"),
        status: 'success',
        isClosable: true,
        duration: 5000,
      })

    } catch(err) {
      if (err instanceof z.ZodError) {
        const flatErrorInfo = err.flatten()
        if(flatErrorInfo.fieldErrors["currentPassword"]) setCurrentPasswordError(flatErrorInfo.fieldErrors["currentPassword"][0]);
        if(flatErrorInfo.fieldErrors["newPassword"]) setNewPasswordError(flatErrorInfo.fieldErrors["newPassword"][0]);
        if(flatErrorInfo.fieldErrors["confirmPassword"]) setConfirmPasswordError(flatErrorInfo.fieldErrors["confirmPassword"][0]);
      }
    }
  }
  
  // Whereby APIキーの更新イベント
  const handleUpdateWherebyAPIKey = async () => {

    if (!authUser) return;

    try {
      await useSetting.updateSetting(authUser, {wherebyAPIKey: integrationSettingForm.wherebyAPIKey});

      toast({
        title: t("message.text.action.setting_mail_success"),
        status: 'success',
        isClosable: true,
        duration: 5000,
      })

    } catch(err) {

      toast({
        title: t("message.text.action.happen_error"),
        status: 'error',
        isClosable: true,
        duration: 5000,
      })
      
    }
  }


  if(!authUser) return (<Loading />);

  return (
    <>
      <Heading m={8} textAlign='left'>{t("screen.text.settings.page_header")}</Heading>

      <Box p={8}>
        <Text fontSize='xl' textAlign='left'>{t("screen.text.settings.label_email")}</Text>

        <Input variant='outline' color='gray.600' placeholder={t("input.placeholder.settings.email")!} onChange={handleUserAuthFormChanges('email')} defaultValue={userAuthSettingForm.email} size='lg' mt={4}/>
        {emailError && <Text fontSize='sm' textAlign='left' color='tomato'>　{emailError}</Text>}


        <Box textAlign='right' mt={4} mb={12}>
          <Button onClick={handleUpdateEmail} color='teal.300' size='lg'>{t("button.text.settings.update_mail")}</Button>
        </Box>


        <Text fontSize='xl' textAlign='left'>{t("screen.text.settings.label_password")}</Text>

        <Input type="password" variant='outline' color='gray.600' placeholder={t("input.placeholder.settings.current_password")!} onChange={handleUserAuthFormChanges('currentPassword')} defaultValue={userAuthSettingForm.currentPassword}  size='lg' mt={4}/>
        {currentPasswordError && <Text fontSize='sm' textAlign='left' color='tomato'>　{currentPasswordError}</Text>}

        <Input type="password" variant='outline' color='gray.600' placeholder={t("input.placeholder.settings.new_password")!} onChange={handleUserAuthFormChanges('newPassword')} defaultValue={userAuthSettingForm.newPassword}  size='lg' mt={4}/>
        {newPasswordError && <Text fontSize='sm' textAlign='left' color='tomato'>　{newPasswordError}</Text>}

        <Input type="password" variant='outline' color='gray.600' placeholder={t("input.placeholder.settings.confirm_password")!} onChange={handleUserAuthFormChanges('confirmPassword')} defaultValue={userAuthSettingForm.confirmPassword}  size='lg' mt={4}/>
        {confirmPasswordError && <Text fontSize='sm' textAlign='left' color='tomato'>　{confirmPasswordError}</Text>}

        <Box textAlign='right' mt={4} mb={12}>
          <Button onClick={handleUpdatePassword} color='teal.300' size='lg'>{t("button.text.settings.update_password")}</Button>
        </Box>


        <Text fontSize='xl' textAlign='left'>{t("screen.text.settings.label_others")}</Text>

        <Text fontSize='lg' textAlign='left' mt={6}>{t("screen.text.settings.label_where_by_token")}</Text>
        <Text fontSize='sm' textAlign='left' color='gray.600' ><a href='https://whereby.com/information/embedded/'>{t("screen.text.settings.label_where_by_token")}</a>{t("screen.text.settings.label_where_by_token_description")}</Text>
        <Input type="password" variant='outline' color='gray.600' placeholder={t("input.placeholder.settings.where_by_token")!} onChange={handleIntegrationFormChanges('wherebyAPIKey')} defaultValue={integrationSettingForm.wherebyAPIKey}  size='lg' mt={4}/>

        <Box textAlign='right' mt={4} mb={12}>
          <Button onClick={handleUpdateWherebyAPIKey} color='teal.300' size='lg'>{t("button.text.settings.update_others")}</Button>
        </Box>



      </Box>

    </>
  );
}
