import { useRecoilCallback } from 'recoil';
import { firestore } from '../firebase';
import { User } from '@firebase/auth-types';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { settingState } from '../State/SettingState';
import { SettingConverter, Setting } from '../Model/Setting';

export const useSettingHook = () => {

  const fetchSetting = useRecoilCallback(({ set }) => async (authUser: User) => {

    console.log('SettingHook fetchSetting start');

    const docRef = doc(firestore, `/users/${authUser.uid}/setting/general`).withConverter(SettingConverter);
    const snapshot = await getDoc(docRef);
    set(settingState, snapshot.data() || {});

    console.log('SettingHook fetchSetting end');
  });

  const updateSetting = useRecoilCallback(({ set }) => async (authUser:User, data:Setting) => {

    console.log('SettingHook updateSetting start');

    if (!authUser) return;
    if (!data) return;

    const docRef = doc(firestore, `/users/${authUser.uid}/setting/general`).withConverter(SettingConverter);
    await setDoc(docRef, SettingConverter.toFirestore(data), {merge: true});
    await fetchSetting(authUser);

    console.log('SettingHook updateSetting end');
  });


  return {
    fetchSetting,
    updateSetting,
  }
}
