import { z } from "zod";
import { AddIcon, EditIcon, DeleteIcon, CheckIcon, NotAllowedIcon, RepeatIcon } from '@chakra-ui/icons';
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Member } from "../Model/Member";
import { authState } from "../State/AuthState";
import { membersState } from "../State/MembersState";
import { useMembersHook } from "../Hook/MembersHook";
import Loading from "../Component/Loading";
import { useTeamsHook } from "../Hook/TeamsHook";
import { teamsState } from "../State/TeamsState";
import { Tr, Td, Heading, Tbody, Th, Thead, TableContainer, Table, Button, Input, Text, Select, IconButton, ButtonGroup, useToast, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Center, Badge, Tooltip, Flex, Box, Spacer, useBreakpointValue, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import UserAvatar from "../Component/UserAvatar";
import { Link as RouterLink } from "react-router-dom";
import WorkReportButton from "../Component/WorkReportButton";

const REGISTERABL_MAX_MEMBER_COUNT = 10;

/*******************************************************************
 * 追加用の行コンポーネント
 *******************************************************************/

const AddRow = () => {

  // i18n Transration
  const { t } = useTranslation();

  const useMembers = useMembersHook(); 
  const authUser = useRecoilValue(authState);
  const teams = useRecoilValue(teamsState);
  const members = useRecoilValue(membersState);

  const initialForm = {
    name: "",
    email: "",
    teamId: ""
  };

  const [form, setForm] = useState(initialForm);
  const [nameError, setNameError] = useState<string|null>(null);
  const [emailError, setEmailError] = useState<string|null>(null);

  const toast = useToast();

  if(!authUser) return (<Loading />);

  // 各入力コンポーネントの値反映用ハンドラー
  const handleChange = (input:string) => (e: { target: { value: string; }; }) => {
    setForm({...form, [input] : e.target.value});
  };

  // 登録処理
  const handleRegister = () => {

    setNameError(null)
    setEmailError(null)

    if (members.length >= REGISTERABL_MAX_MEMBER_COUNT) {
      toast({
        title: t("message.text.action.members_count_over"),
        status: 'error',
        duration: 2000,
      })
      return;
    }

    const FormValidator = z.object({
      name: z.string()
              .min(1, { message: t("message.text.validation.required_name")! }),
      email: z.string()
              .min(1, { message: t("message.text.validation.required_email")! })
              .email( { message: t("message.text.validation.invalid_email")! }),
    })

    try {
      FormValidator.parse(form)
      useMembers.addMember(authUser, form);
      setForm(initialForm);

      toast({
        title: t("message.text.action.add_member_success"),
        status: 'success',
        duration: 2000,
      })

    } catch(err) {

      toast({
        title: t("message.text.action.happen_error"),
        status: 'error',
        duration: 2000,
      })

      if (err instanceof z.ZodError) {
        const flatErrorInfo = err.flatten()
        if(flatErrorInfo.fieldErrors["name"]) setNameError(flatErrorInfo.fieldErrors["name"][0]);
        if(flatErrorInfo.fieldErrors["email"]) setEmailError(flatErrorInfo.fieldErrors["email"][0]);
      }
    }
  }

  return (
    <Tr>
      <Td>
        <Input variant='outline' color='gray.600' placeholder={t("input.placeholder.name")!} onChange={handleChange('name')} defaultValue="" required/>
        {nameError && <Text fontSize='sm' textAlign='left' color='tomato'>　{nameError}</Text>}
      </Td>
      <Td>
        <Input variant='outline' color='gray.600' placeholder={t("input.placeholder.email")!} onChange={handleChange('email')} defaultValue="" required/>
        {emailError && <Text fontSize='sm' textAlign='left' color='tomato'>　{emailError}</Text>}
      </Td>
      <Td>
        <Select placeholder={t("input.placeholder.join_team")!} onChange={handleChange('teamId')}>
          <option value=''></option>
          {teams.map((team) => (
            <option  key={team.id} value={team.id}>{team.name}</option>
            ))}
        </Select>
      </Td>
      <Td>
        <Button onClick={handleRegister} rightIcon={<AddIcon />} color='teal.300' variant='solid' disabled={(members.length >= REGISTERABL_MAX_MEMBER_COUNT)}>{t("button.text.add_member")}</Button>
      </Td>
    </Tr>
   );
}



/*******************************************************************
 * 修正用の行コンポーネント
 *******************************************************************/

interface EditProps {
  member: Member;
}

const EditRow = (props: EditProps) => {

  // i18n Transration
  const { t } = useTranslation();
  const useMembers = useMembersHook(); 

  const {member} = props;
  const authUser = useRecoilValue(authState);
  const teams = useRecoilValue(teamsState);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [form, setForm] = useState(member);
  const [nameError, setNameError] = useState<string|null>(null);
  const [emailError, setEmailError] = useState<string|null>(null);

  const [isDeleteComfirmOpen, setIsDeleteComfirmOpen] = useState(false);

  const toast = useToast();

  if(!authUser) return (<Loading />);

  // 各入力コンポーネントの値反映用ハンドラー
  const handleChange = (input:string) => (e: { target: { value: string; }; }) => {
    setForm({...form, [input] : e.target.value});
  };

  // 編集状態の切り替え用メソッド
  const toggleFormState = (value:boolean) => {
    setNameError(null)
    setEmailError(null)
    setForm(member);
    setIsEdit(value);
  }

  // 更新処理
  const handleUpdate = () => {

    setNameError(null)
    setEmailError(null)

    const FormValidator = z.object({
      name: z.string()
              .min(1, { message: t("message.text.validation.required_name")! }),
      email: z.string()
              .min(1, { message: t("message.text.validation.required_email")! })
              .email( { message: t("message.text.validation.invalid_email")! }),
    })

    try {
      FormValidator.parse(form)
      useMembers.updateMember(authUser, form);
      setIsEdit(false);

      toast({
        title: t("message.text.action.update_member_success"),
        status: 'success',
        duration: 2000,
      })

    } catch(err) {
      toast({
        title: t("message.text.action.happen_error"),
        status: 'error',
        duration: 2000,
      })

      if (err instanceof z.ZodError) {
        const flatErrorInfo = err.flatten()
        if(flatErrorInfo.fieldErrors["name"]) setNameError(flatErrorInfo.fieldErrors["name"][0]);
        if(flatErrorInfo.fieldErrors["email"]) setEmailError(flatErrorInfo.fieldErrors["email"][0]);
      }
    }
  }

  // 削除処理
  const handleDelete = () => {
    try {
      useMembers.deleteMember(authUser, member);
      toast({
        title: t("message.text.action.delete_member_success"),
        status: 'success',
        duration: 2000,
      })

    } catch(err) {
      toast({
        title: t("message.text.action.happen_error"),
        status: 'error',
        duration: 2000,
      })

    }
  }

  const getTeamName = (teamId:string) => {
    const team = teams.find(team => team.id === teamId);
    return team?.name || "";
  }

  return (
    <Tr>
      <Td>
        {
          isEdit ?
          <>
            <Input variant='outline' color='gray.600' placeholder={t("input.placeholder.name")!} onChange={handleChange('name')} defaultValue={member.name} required/>
            {nameError && <Text fontSize='sm' textAlign='left' color='tomato'>　{nameError}</Text>}
          </> :
          <>
          <Flex>
            <UserAvatar key={member.id} member={member} />
            <Box ml='5'>
              <Text fontWeight='bold'>
                {member.name}

                {member.detect ? 
                <Badge ml='2' colorScheme='green'>{t("screen.text.members.working")}</Badge>
                : 
                <Badge ml='2' colorScheme='red'>{t("screen.text.members.not_working")}</Badge>
                }
              </Text>

              {member.loggined ?
              <Text fontSize='sm' color="teal">{t("screen.text.members.loggined")}</Text>
              :
              <Text fontSize='sm' color="grey">{t("screen.text.members.not_loggined")}</Text>
              }
              
            </Box>
          </Flex>
          </>
        }
      </Td>
      <Td>
        {
          isEdit ?
          <>
            <Input variant='outline' color='gray.600' placeholder={t("input.placeholder.email")!} onChange={handleChange('email')} defaultValue={member.email} required/>
            {emailError && <Text fontSize='sm' textAlign='left' color='tomato'>{emailError}</Text>}
          </> :
          <label>{member.email}</label>
        }
      </Td>
      <Td>
        {
          isEdit ?
            <Select placeholder={t("input.placeholder.join_team")!} onChange={handleChange('teamId')} value={form.teamId}>
              <option value=''></option>
              {teams.map((team) => (
                <option  key={team.id} value={team.id}>{team.name}</option>
                ))}
            </Select> :
          <label>
            <Link  color='teal.500' as={RouterLink} to={`/teams/${member.teamId}`}>{getTeamName(member.teamId)}</Link>
          </label>
        }
      </Td>
      <Td>
        {
          isEdit ? (
            <ButtonGroup variant='outline' spacing='2'>
              <Tooltip label={t("button.text.update")!}>
                <IconButton onClick={() => {handleUpdate()}} icon={<CheckIcon />} aria-label="edit member"/>
              </Tooltip>
              <Tooltip label={t("button.text.cancel")!}>
                <IconButton onClick={() => {toggleFormState(false)}} icon={<NotAllowedIcon />} aria-label="delete member"/>
              </Tooltip>
            </ButtonGroup>
          ) : (
            <ButtonGroup variant='outline' spacing='2'>
              <WorkReportButton key={member.id} member={member} baseDate={new Date()} />
              <Tooltip label={t("button.text.edit_member")!}>
                <IconButton onClick={() => {toggleFormState(true)}} icon={<EditIcon />} aria-label="edit member"/>
              </Tooltip>
              <Tooltip label={t("button.text.delete_member")!}>
                <IconButton onClick={() => setIsDeleteComfirmOpen(true)} icon={<DeleteIcon />} aria-label="delete member"/>
              </Tooltip>
            </ButtonGroup>
          )  
        }
      </Td>

      <Modal
        isOpen={isDeleteComfirmOpen}
        onClose={() => setIsDeleteComfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>{t("screen.text.members.confirm_dialog_title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {t("screen.text.members.confirm_dialog_text_1")}<br/>{t("screen.text.members.confirm_dialog_text_2")}

            <Center w='full' mt={16}>
              <ButtonGroup variant='outline' spacing='6'>
                <Button onClick={handleDelete} color='teal.300' >{t("button.text.delete")}</Button>
                <Button onClick={() => setIsDeleteComfirmOpen(false)}color='gray.500' variant='outline'>{t("button.text.cancel")}</Button>
              </ButtonGroup>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

    </Tr>
  );
}


/*******************************************************************
 * メインコンポーネント
 *******************************************************************/
 export const Members = () => {

  const useMembers = useMembersHook(); 
  const useTeams = useTeamsHook(); 
  const authUser = useRecoilValue(authState);
  const members = useRecoilValue(membersState);

  useEffect(() => {
    if(!authUser) return;
    useMembers.fetchMembers(authUser);
    // ↓　linterの指摘通りにhookを第二引数に含めると無限ループする　↓
    // eslint-disable-next-line
  }, [authUser]); 

  useEffect(() => {
    if(!authUser) return;
    useTeams.fetchTeams(authUser);
    // ↓　linterの指摘通りにhookを第二引数に含めると無限ループする　↓
    // eslint-disable-next-line
  }, [authUser]); 

  const refleshStatus = () => {
    if(!authUser) return;
    useTeams.fetchTeams(authUser);
    useMembers.fetchMembers(authUser);
  }


  const RefleshButtonComponent = useBreakpointValue(
    {
      base: '', /* モバイル端末の時はリロードが簡単なので更新ボタンは表示しない */
      md: <Button m={8} leftIcon={<RepeatIcon />} color='teal.300' size='lg' onClick={refleshStatus}>{t("screen.text.members.label_reflesh_status")}</Button>,
    },
  )

  const HeaderComponent = useBreakpointValue(
    {
      base: (
        <Heading m={8} textAlign='left'>{t("screen.text.members.page_header")}
          <Badge ml={8} p={2} fontSize='2xl' colorScheme='green'> {members.length} / {REGISTERABL_MAX_MEMBER_COUNT} </Badge>
        </Heading>
      ),
      md: (
        <Heading m={8} textAlign='left'>{t("screen.text.members.page_header")}
          <Badge ml={8} p={2} fontSize='2xl' colorScheme='green'> {members.length} / {REGISTERABL_MAX_MEMBER_COUNT} </Badge>
        </Heading>
      ),
    },
  )

  if(!authUser) return (<Loading />);

  return (
    <>
      <Flex>
        {HeaderComponent}
        <Spacer />
        {RefleshButtonComponent}
      </Flex>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>{t("screen.text.members.label_name")}</Th>
              <Th>{t("screen.text.members.label_email")}</Th>
              <Th>{t("screen.text.members.label_join_team")}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            <AddRow/>
            {members.map((member) => (
                <EditRow key={member.id} member={member}/>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

