import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/analytics"
import "firebase/compat/functions"

const firebaseConfig = {
  apiKey: "AIzaSyBBsno0jH9-UzhKk6q0gB8DgQJ9WKD05XI",
  authDomain: "pinglly.firebaseapp.com",
  projectId: "pinglly",
  storageBucket: "pinglly.appspot.com",
  messagingSenderId: "936443220742",
  appId: "1:936443220742:web:957383659911afb292c487",
  measurementId: "G-NF0J1RXD7X"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore(firebaseApp);
export const analytics = firebase.analytics(firebaseApp);
export const functions = firebase.functions(firebaseApp);

export default firebase;
