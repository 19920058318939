import { collection, getDocs } from '@firebase/firestore';
import { useRecoilCallback } from 'recoil';
import { firestore } from '../firebase';
import { User } from '@firebase/auth-types';
import {v4 as uuidv4} from 'uuid';
import { doc, query, runTransaction, setDoc, where } from 'firebase/firestore';
import { teamsState } from '../State/TeamsState';
import { Team, TeamConverter } from '../Model/Team';
import { MemberConverter } from '../Model/Member';
import { teamMembersState } from '../State/TeamMembersState';

export const useTeamsHook = () => {

  const fetchTeams = useRecoilCallback(({ set }) => async (authUser: User) => {

    console.log('TeamsHook fetchTeams start');

    const collectionRef = collection(firestore, `/users/${authUser.uid}/teams`).withConverter(TeamConverter);
    const snapshots = await getDocs(collectionRef);
    const teams = snapshots.docs.map((snapshot) => snapshot.data());
    set(teamsState, teams);
  
    console.log('TeamsHook fetchTeams end');
  });

  const fetchTeamMembers = useRecoilCallback(({ set }) => async (authUser: User, teamId:string) => {

    console.log('TeamsHook fetchTeamMembers start');

    set(teamMembersState, []);
    const collectionRef = collection(firestore, `/users/${authUser.uid}/members`).withConverter(MemberConverter);
    const q = query(collectionRef, where("teamId", "==", teamId));
    const snapshots = await getDocs(q);
    const members = snapshots.docs.map((snapshot) => snapshot.data());
    set(teamMembersState, members);
  
    console.log('TeamsHook fetchTeamMembers end');
  });

  const addTeam = useRecoilCallback(({ set }) => async (authUser:User, data:Team) => {

    console.log('TeamsHook addTeam start');

    if (!authUser) return;
    if (!data) return;

    const teamUUID = uuidv4();

    data = {...data, ...{baseWorkHours: 8, longestWorkHours: 12}};

    const docRef = doc(firestore, `/users/${authUser.uid}/teams/${teamUUID}`).withConverter(TeamConverter);
    await setDoc(docRef, TeamConverter.toFirestore(data), {merge: true});
    await fetchTeams(authUser);

    console.log('TeamsHook addTeam end');
  });

  const updateTeam = useRecoilCallback(({ set }) => async (authUser:User, data:Team) => {

    console.log('TeamsHook updateTeam start');

    if (!authUser) return;
    if (!data) return;

    const docRef = doc(firestore, `/users/${authUser.uid}/teams/${data.id}`).withConverter(TeamConverter);
    await setDoc(docRef, TeamConverter.toFirestore(data), {merge: true});
    await fetchTeams(authUser);

    console.log('TeamsHook updateTeam end');
  });

  const deleteTeam = useRecoilCallback(({ set }) => async (authUser: User, data: Team) => {

    console.log('TeamsHook deleteTeam start');

    if (!authUser) return;
    if (!data) return;

    const collectionRef = collection(firestore, `/users/${authUser.uid}/members`).withConverter(MemberConverter);
    const q = query(collectionRef, where("teamId", "==", data.id));
    const snapshots = await getDocs(q);

    try {
      await runTransaction(firestore, async (transaction) => {

        const docRef = doc(firestore, `/users/${authUser.uid}/teams/${data.id}`).withConverter(TeamConverter);
        transaction.delete(docRef);

        snapshots.docs.forEach((snapshot) => {
          transaction.update(snapshot.ref, {teamId: ""});
        });
    
      });

    } catch (e) {

    }
    
    await fetchTeams(authUser);

    console.log('TeamsHook deleteTeam end');
  });


  return {
    fetchTeams,
    fetchTeamMembers,
    addTeam,
    updateTeam,
    deleteTeam
  }
}
