import { useSetRecoilState } from 'recoil';
import { authState } from '../State/AuthState';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Input, Image, useToast, Text, Link, ButtonGroup, Divider, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { FaApple, FaWindows } from 'react-icons/fa';

export default function SignUp() {

  // Navigation
  const navigate = useNavigate();

  // Recoil State
  const setAuth = useSetRecoilState(authState);

  // Message Provider
  const toast = useToast();

  // i18n Transration
  const { t } = useTranslation();


  const initialForm = {
    email: "",
    password: "",
    confirmPassword: "",
  };
  // フォーム用のローカルStateと値設定用のイベント定義
  const [form, setFrom] = useState(initialForm);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const handleFormChange = (input:string) => (e: { target: { value: string; }; }) => {
    setFrom({...form, [input] : e.target.value});
  };


  const handleSignup = async () => {

    setEmailError('');
    setPasswordError('');
    setConfirmPasswordError('');

    const FormValidator = z.object({
      email: z.string().min(1, { message: "Eメールが未入力です" }),
      password: z.string().min(1, { message: "パスワードが未入力です" }),
      confirmPassword: z.string().min(1, { message: "確認用パスワードが未入力です" }),
    })

    try {
      FormValidator.parse(form);

      if(form.password !== form.confirmPassword) {
        toast({
          title: `ログインに失敗しました`,
          status: 'error',
          duration: 2000,
        })

        setPasswordError('パスワードが一致していません')
        return;
      }

      const authResult = await auth.createUserWithEmailAndPassword(form.email, form.password);
      if (authResult.user) {
        setAuth(authResult.user);
        navigate("/");
        toast({
          title: `Pinglyへようこそ`,
          status: 'success',
          duration: 2000,
        })
      }

    } catch(err) {

      toast({
        title: `ログインに失敗しました`,
        status: 'error',
        duration: 2000,
      })

      if (err instanceof z.ZodError) {
        const flatErrorInfo = err.flatten()
        if(flatErrorInfo.fieldErrors["email"]) setEmailError(flatErrorInfo.fieldErrors["email"][0]);
        if(flatErrorInfo.fieldErrors["password"]) setPasswordError(flatErrorInfo.fieldErrors["password"][0]);
        if(flatErrorInfo.fieldErrors["confirmPassword"]) setConfirmPasswordError(flatErrorInfo.fieldErrors["confirmPassword"][0]);
      }
    }

  };
  
  return (
    <VStack h='100vh' w='100vw' backgroundColor="teal.300" overflowX='hidden'>
      <Box m={16} p={8} pt={16} pb={8} w='90vw' maxWidth='600px' minHeight="980px" borderWidth='1px' borderRadius='lg' overflow='hidden' backgroundColor="#ffffff">

        <Image mb={16} src='images/PinglyLogoWhiteBar.png' alt='Logo'/>

        <Input variant='outline' size='lg' color='gray.600' placeholder={t("input.placeholder.email")!} onChange={handleFormChange('email')} defaultValue="" required />
        <Text mb={4} fontSize='sm' textAlign='left' color='tomato'>　{emailError}</Text>

        <Input variant='outline' type='password' size='lg' color='gray.600' placeholder={t("input.placeholder.password")!} onChange={handleFormChange('password')} defaultValue="" autoComplete="new-password" required />
        <Text mb={4} fontSize='sm' textAlign='left' color='tomato'>　{passwordError}</Text>

        <Input variant='outline' type='password' size='lg' color='gray.600' placeholder={t("input.placeholder.confirm_password")!} onChange={handleFormChange('confirmPassword')} defaultValue="" autoComplete="confirm-password" required />
        <Text mb={4} fontSize='sm' textAlign='left' color='tomato'>　{confirmPasswordError}</Text>

        <Center>
          <Button onClick={handleSignup} rightIcon={<ArrowForwardIcon />} color='teal.300' variant='solid' size='lg'>{t("button.text.signup")}</Button>
        </Center>

        <Center mt={8}>
          <Text>
            {t("screen.text.signup.exists_account_prefix")}{' '}
            <Link color='teal.500' as={RouterLink} to='/signin'>
            {t("screen.text.signup.exists_account_link")}
            </Link>
          </Text>
        </Center>

        <Center mt={16}>
          <Text>
          {t("screen.text.signin.client_download_here")}
          </Text>
        </Center>
        <Center>
          <Button leftIcon={<FaWindows />} onClick={() => window.open('https://pingly-desktop.s3.ap-northeast-1.amazonaws.com/win/PinglyDesktop.exe', '_blank')} variant='solid' size='lg' m='2'>　Win　</Button>
          <Button leftIcon={<FaApple />} variant='solid' size='lg' m='2' disabled>　Mac　</Button>
        </Center>

        <Divider mt={16} mb={4} />
        <VStack pt="4" pb="4" justify="space-between" direction={{ base: 'column-reverse', md: 'row' }} align="center" >
          <ButtonGroup variant="ghost">
            <Link color='teal.800' href='/terms.html' fontSize="sm">利用規約</Link>
            <Link color='teal.800' href='/policy.html' fontSize="sm">プライバシーポリシー</Link>
          </ButtonGroup>
          <Text fontSize="sm" color="teal.800">
            &copy; {new Date().getFullYear()} Pingly.work. All rights reserved.
          </Text>
        </VStack>

      </Box>

    </VStack>
  );
}