import { useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip, Heading, HStack, Box, Text, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Avatar, Flex, Wrap, WrapItem, Image, Stack, Spinner, useBoolean, Center, VStack, Tag } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
import React, { useEffect } from 'react';
import { TbChartInfographic } from 'react-icons/tb'
import { Member } from '../Model/Member';
import { useWorkReportsHook } from '../Hook/WorkReportsHook';
import { useRecoilValue } from 'recoil';
import { authState } from '../State/AuthState';
import { workReportsState } from '../State/WorkReportsState';
import dayjs from '../dayjs';
import { WorkReport } from '../Model/WorkReport';


type Props = {
    member: Member;
    baseDate: Date;
}

const WorkReportButton: React.FC<Props> = ({member, baseDate}) => {

    // Hook
    const useWorkReports = useWorkReportsHook(); 
    const [loading, setLoading] = useBoolean(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    // i18n Transration
    const { t } = useTranslation();

    // State
    const authUser = useRecoilValue(authState);
    const currentWorkReports = useRecoilValue(workReportsState);


    useEffect(() => {
        if(!authUser) return;

        if(isOpen) {

            setLoading.on();
            const startDate = dayjs.tz(`${baseDate.getFullYear()}-${baseDate.getMonth()+1}-01`, 'UTC');
            const endDate = dayjs.tz(baseDate, 'UTC');
            
            const workReportTargetDays:Date[] = [];
            for(let date=startDate; date<=endDate; date=date.add(1, 'day')){
              workReportTargetDays.push(date.toDate())
            }

            const fetchData = async () => {
                await useWorkReports.fetchCurrentWorkReports(authUser, member, workReportTargetDays);
                setLoading.off();
            } 

            fetchData();
        }
    // eslint-disable-next-line
    }, [isOpen, authUser])

    return (
        <>
            <Tooltip label={t("button.text.work_report")!}>
                <IconButton onClick={onOpen} icon={<TbChartInfographic />} aria-label="work report"/>
            </Tooltip>
            <Modal
                isOpen={isOpen} 
                onClose={onClose}
                size='6xl'
                scrollBehavior='inside'
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <ModalOverlay />
                <ModalContent p={4}>
                <ModalHeader>{t('screen.text.members.label_work_report')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    {loading ? (

                    <Center h='xl'>
                        <Spinner size='xl' />
                    </Center>

                    ) : (

                    <Tabs colorScheme='black'>
                        <TabList>
                            <Tab>{t('screen.text.members.label_current_month_work_report')}</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                            {/** 24時間を５分ごとに分割し、それぞれのレポートデータを用いて表現する */}
                            {currentWorkReports.map(workReport => {
                                const baseDate = dayjs.tz(workReport.key, 'UTC').format("YYYY-MM-DD");
                                const workReportMap: Record<string, WorkReport> = {};
                                workReport.value.forEach(report => {
                                    const targetDate = dayjs(report.updatedAt);
                                    const hour = targetDate.hour();
                                    const minute = Math.floor(targetDate.minute() / 5);
                                    const key = `${hour}:${minute}`;
                                    workReportMap[key] = report;
                                });

                                return (
                                <Box>
                                    {baseDate}
                                    <HStack spacing='0px' m="1">
                                    {
                                        [...Array(24)].map((_, hour) => {
                                            return [...Array(12)].map((_, minute) => {
                                                const key = `${hour}:${minute}`;
                                                if (workReportMap[key] !== undefined){
                                                    const workReport = workReportMap[key];
                                                    const backgroundColor = workReport.detect ? 'teal.200' : 'gray.400';
                                                    return (
                                                        <Popover trigger='hover'>
                                                            <PopoverTrigger>
                                                                <VStack w='5vw' h='80px' spacing={0}>
                                                                    <Box key={key} w='100%' h='40px' bg={backgroundColor} />
                                                                    {minute === 0 ? <Box key={key + 'num'} h='40px' color='gray.600' fontSize='xs'>{hour}</Box> : <></>}
                                                                </VStack>
                                                            </PopoverTrigger>
                                                            <PopoverContent>
                                                                <PopoverArrow />
                                                                <PopoverCloseButton />
                                                                <PopoverHeader>
                                                                    <Tag fontSize='sm' bgColor={backgroundColor}>{dayjs.tz(workReport.updatedAt).format('HH:mm')}</Tag>
                                                                    <Stack mt='4' gap='4'>
                                                                        <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                                                                            <Avatar name={workReport.name} src={workReport.currentPicture} size="lg" />
                                                                            <Box>
                                                                                <Heading size='sm'>{workReport.name}</Heading>
                                                                                <Text fontSize='sm' color="grey">
                                                                                    {
                                                                                    (workReport.detect ? t("screen.text.members.working") : t("screen.text.members.not_working")) +
                                                                                    "　-　" + 
                                                                                    (workReport.loggined ? t("screen.text.members.loggined") : t("screen.text.members.not_loggined"))
                                                                                    }
                                                                                </Text>
                                                                            </Box>
                                                                        </Flex>
                                                                        <Text fontSize='sm' color="grey">{workReport.currentApp}</Text>
                                                                    </Stack>
                                                                </PopoverHeader>
                                                                <PopoverBody>
                                                                { workReport.currentDesktopCaptures ? (
                                                                    <Wrap spacing='24px' mt="4">
                                                                        {workReport.currentDesktopCaptures?.map(capture => {
                                                                            return (
                                                                                <WrapItem key={capture} w={"full"}>
                                                                                    <Image src={capture} htmlWidth="100%" />
                                                                                </WrapItem>
                                                                            )
                                                                        })}
                                                                    </Wrap>
                                                                    ) : (
                                                                        <Text color="grey">No Desktop Capture Image</Text>
                                                                    )}

                                                                </PopoverBody>
                                                            </PopoverContent>
                                                        </Popover>
                                                        // <Tooltip label={`${dayjs.tz(workReport.updatedAt).format('YYYY/MM/DD HH:mm:ssZ')} - ${workReport.detect ? '在席' : '離席'}`}>
                                                        // </Tooltip>
                                                    );
                                                } else {
                                                    return (
                                                        <Tooltip label={t('screen.text.members.not_loggined')}>
                                                            <VStack w='5vw' h='80px' spacing={0}>
                                                                <Box key={key} w='100%' h='40px' bg='gray.100' />
                                                                {minute === 0 ? <Box key={key + 'num'} h='40px' color='gray.300' fontSize='xs'>{hour}</Box> : <></>}
                                                            </VStack>
                                                        </Tooltip>
                                                    );
                                                }
                                            });
                                        })
                                    }
                                    </HStack>
                                </Box>
                                )
                            })}

                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                    )}
                    
                </ModalBody>
                </ModalContent>
            </Modal>

        </>
      )
};

export default WorkReportButton;