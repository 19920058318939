import { User } from '@firebase/auth-types';
import { atom } from 'recoil';

type AuthState = User| null;

export const authState = atom<AuthState>({
  key: 'authState',
  default: null,
  // TypeError: Cannot freezeを回避
  dangerouslyAllowMutability: true,
});
