import { FirestoreDataConverter, serverTimestamp } from "firebase/firestore";

export type Setting = {
    id?: string;
    wherebyAPIKey?: string;
    updatedAt?: Date;
}

export const SettingConverter: FirestoreDataConverter<Setting> = {
  // 管理アプリから更新する項目だけを対象とする
  toFirestore: (setting) => {
    return {
      wherebyAPIKey: setting.wherebyAPIKey,
      updatedAt: serverTimestamp(),
    };
  },
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    const setting = {
      id: snapshot.id,
      ...data,
      updatedAt: data.updatedAt?.toDate(),
    } as Setting;
    return setting;
  },
};

