import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Loading from "./Component/Loading";
import { auth } from "./firebase";
import Base from "./Page/Base";
import { Contact } from "./Page/Contact";
import { Members } from "./Page/Members";
import { NotFound } from "./Page/NotFound";
import { Settings } from "./Page/Settings";
import SignIn from "./Page/SignIn";
import SignUp from "./Page/SignUp";
import { TeamDetail } from "./Page/TeamDetail";
import { Teams } from "./Page/Teams";
import { authState } from "./State/AuthState";


export default function RouterConfig() {

  const setAuth = useSetRecoilState(authState);
  const authUser = useRecoilValue(authState);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);
      }
      setIsLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <>
     <BrowserRouter>
     {isLoading ? (
       <Loading />
      ) : (
        <Routes>
          <Route path="" element={!authUser ? (<Navigate replace to="/signin" />) : (<Base />)}>
            <Route index element={<Members />} />
            <Route path="members" element={<Members />} /> 
            <Route path="teams" element={<Teams />} /> 
            <Route path="teams/:teamId" element={<TeamDetail />} />
            <Route path="settings" element={<Settings />} /> 
            <Route path="contact" element={<Contact />} /> 
          </Route>
          <Route path="signin" element={<SignIn/>} />
          <Route path="signup" element={<SignUp/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )
    }
    </BrowserRouter>
    </>
  );
}