import { FirestoreDataConverter, serverTimestamp } from "firebase/firestore";

export type MemberSecret = {
  id?: string;
  accessToken?: string,
  currentApp: string;
  currentDesktopCaptures: string[];
  clientVersion: string;
  updatedAt?: Date;
}


export const MemberSecretConverter: FirestoreDataConverter<MemberSecret> = {
  toFirestore: (member) => {
    return {
      accessToken: member.accessToken || '',
      currentApp: member.currentApp || '',
      currentDesktopCaptures: member.currentDesktopCaptures || [],
      clientVersion: member.clientVersion || '',
      updatedAt: serverTimestamp(),
    };
  },
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    const memberSecret = {
      id: snapshot.id,
      ...data,
      updatedAt: data.updatedAt?.toDate(),
    } as MemberSecret;
    return memberSecret;
  },
};

