import {  FirestoreDataConverter, serverTimestamp } from "firebase/firestore";

export type Team = {
    id?: string;
    name?: string;
    updatedAt?: Date;
}

export const TeamConverter: FirestoreDataConverter<Team> = {
  toFirestore: (team) => {
    return {
      name: team.name,
      updatedAt: serverTimestamp(),
    };
  },
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    const team = {
      id: snapshot.id,
      ...data,
      updatedAt: data.updatedAt?.toDate(),
    } as Team;
    return team;
  },
};



