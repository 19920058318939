import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../State/AuthState";
import { teamsState } from "../State/TeamsState";
import { Team } from "../Model/Team";
import { z } from "zod";
import { useTeamsHook } from "../Hook/TeamsHook";
import Loading from "../Component/Loading";
import { Box, Button, ButtonGroup, Center, Flex, Heading, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useToast } from "@chakra-ui/react";
import { AddIcon, DeleteIcon, ViewIcon } from '@chakra-ui/icons';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


/*******************************************************************
 * 追加用の行コンポーネント
 *******************************************************************/

const AddRow = () => {

  // i18n Transration
  const { t } = useTranslation();

  const useTeams = useTeamsHook(); 
  const authUser = useRecoilValue(authState);

  const initialForm = {
    name: "",
  };

  const [form, setForm] = useState(initialForm);
  const [nameError, setNameError] = useState<string|null>(null);

  const toast = useToast();

  if(!authUser) return (<Loading />);

  // 各入力コンポーネントの値反映用ハンドラー
  const handleChange = (input:string) => (e: { target: { value: string; }; }) => {
    setForm({...form, [input] : e.target.value});
  };

  // 登録処理
  const handleRegister = () => {

    setNameError(null)

    const FormValidator = z.object({
      name: z.string()
              .min(1, { message: t("message.text.validation.required_name")! }),
    })

    try {
      FormValidator.parse(form)
      useTeams.addTeam(authUser, form);
      setForm(initialForm);

      toast({
        title: t("message.text.action.add_team_success"),
        status: 'success',
        duration: 2000,
      })

    } catch(err) {

      toast({
        title: t("message.text.action.happen_error"),
        status: 'error',
        duration: 2000,
      })

      if (err instanceof z.ZodError) {
        const flatErrorInfo = err.flatten()
        if(flatErrorInfo.fieldErrors["name"]) setNameError(flatErrorInfo.fieldErrors["name"][0]);
      }
    }
  }

  return (
    <Tr>
      <Td>
        <Input variant='outline' color='gray.600' placeholder={t("input.placeholder.name")!} onChange={handleChange('name')} defaultValue="" required/>
        {nameError && <Text fontSize='sm' textAlign='left' color='tomato'>　{nameError}</Text>}
      </Td>
      <Td>
        <Button onClick={handleRegister} rightIcon={<AddIcon />} color='teal.300' variant='solid'>{t("button.text.add_team")}</Button>
      </Td>
    </Tr>
   );
}



/*******************************************************************
 * 修正用の行コンポーネント
 *******************************************************************/

interface EditProps {
  team: Team;
}

const EditRow = (props: EditProps) => {

  // i18n Transration
  const { t } = useTranslation();
  const navigate = useNavigate();

  const useTeams = useTeamsHook(); 

  const {team} = props;
  const authUser = useRecoilValue(authState);

  const [isDeleteComfirmOpen, setIsDeleteComfirmOpen] = useState(false);

  const toast = useToast();

  if(!authUser) return (<Loading />);

  // 削除処理
  const handleDelete = () => {
    try {
      useTeams.deleteTeam(authUser, team);
      toast({
        title: t("message.text.action.delete_team_success"),
        status: 'success',
        duration: 2000,
      })

    } catch(err) {
      toast({
        title: t("message.text.action.happen_error"),
        status: 'error',
        duration: 2000,
      })

    }
  }

  const navigateDetail = (team:Team) => {
    navigate(`/teams/${team.id}`);
  }

  return (
    <Tr>
      <Td>
          <Flex>
            <Box ml='5'>
              <Text fontWeight='bold'>
                {team.name}
              </Text>
            </Box>
          </Flex>
      </Td>
      <Td>
        <ButtonGroup variant='outline' spacing='2'>
          <Tooltip label={t("button.text.show_team_detail")!}>
            <IconButton onClick={() => navigateDetail(team)} icon={<ViewIcon />} aria-label="show detail team"/>
          </Tooltip>
          <Tooltip label={t("button.text.delete_team")!}>
            <IconButton onClick={() => setIsDeleteComfirmOpen(true)} icon={<DeleteIcon />} aria-label="delete team"/>
          </Tooltip>
        </ButtonGroup>
      </Td>

      <Modal
        isOpen={isDeleteComfirmOpen}
        onClose={() => setIsDeleteComfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>{t("screen.text.members.confirm_dialog_title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {t("screen.text.teams.confirm_delete_dialog_text_1")}<br/>{t("screen.text.teams.confirm_delete_dialog_text_2")}

            <Center w='full' mt={16}>
              <ButtonGroup variant='outline' spacing='6'>
                <Button onClick={handleDelete} color='teal.300' >{t("button.text.delete")}</Button>
                <Button onClick={() => setIsDeleteComfirmOpen(false)}color='gray.500' variant='outline'>{t("button.text.cancel")}</Button>
              </ButtonGroup>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

    </Tr>
  );
}



/*******************************************************************
 * チーム一覧コンポーネント
 *******************************************************************/
 export const Teams = () => {

  // Hooks ******************************************
  const useTeams = useTeamsHook();                
  // i18n Transration
  const { t } = useTranslation();

  const teams = useRecoilValue(teamsState);     // 表示用データの全チーム情報
  const authUser = useRecoilValue(authState);   // 現在のログインユーザー

  useEffect(() => {
    if(!authUser) return;
    useTeams.fetchTeams(authUser);
    // eslint-disable-next-line
  }, [authUser]); 


  if(!authUser) return (<Loading />);

  return (
    <>
      <Flex>
        <Heading m={8} textAlign='left'>{t("screen.text.teams.page_header")}</Heading>
      </Flex>
      <TableContainer m={4}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>{t("screen.text.teams.label_team_name")}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            <AddRow/>
            {teams.map((team) => (
                <EditRow key={team.id} team={team}/>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );

}


