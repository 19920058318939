import { FirestoreDataConverter } from "firebase/firestore";

export type WorkReport = {
    id?: string;
    name: string;
    email: string;
    teamId:string;
    detect?: boolean;
    enableCamera?: boolean;
    loggined?: boolean;
    icon?: string;
    message? :string;
    currentPicture?: string;
    lastDetectedAt?: string;
    accessToken?: string,
    currentApp: string;
    currentDesktopCaptures: string[];
    clientVersion: string;
    updatedAt?: Date;
  
}

export const WorkReportConverter: FirestoreDataConverter<WorkReport> = {
  // 管理アプリから更新する項目だけを対象とする
  toFirestore: (workReport) => {
    return {};
  },
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    const workReport = {
      id: snapshot.id,
      ...data,
      updatedAt: data.updatedAt?.toDate(),
    } as WorkReport;
    return workReport;
  },
};

