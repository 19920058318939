import {Box,Flex,IconButton,Button,Stack,Collapse,useColorModeValue,useBreakpointValue,useDisclosure, Heading,} from '@chakra-ui/react';
import {HamburgerIcon,CloseIcon,} from '@chakra-ui/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { useTranslation } from 'react-i18next';

interface NavItem {
  label: string;
  href: string;
}


export default function Home() {

  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  // i18n Transration
  const { t } = useTranslation();

  const handleLogout = async () => {
    await auth.signOut();
    await navigate("/signin", {replace: true});
  }

  return (
    <Box>
      <Flex
        bg={useColorModeValue('teal.300', 'gray.800')}
        color={useColorModeValue('white', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Heading 
            size='lg' 
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            color={useColorModeValue('white', 'white')}
            fontSize='28px'>
          Pingly
          </Heading>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
          <Button colorScheme='teal' variant='ghost' onClick={handleLogout}>{t("button.text.signout")}</Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>

      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Outlet />
      </Box>
    </Box>
  );
}

const DesktopNav = () => {

  const navigate = useNavigate();
  // i18n Transration
  const { t } = useTranslation();

  const NAV_ITEMS: Array<NavItem> = [
    {
      label: t("menu.text.manage_member"),
      href: '/members',
    },
    {
      label: t("menu.text.manage_team"),
      href: '/teams',
    },
    {
      label: t("menu.text.settings"),
      href: '/settings',
    },
    {
      label: t("menu.text.contact"),
      href: '/contact',
    }
  ];

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Button colorScheme='teal' variant='ghost' onClick={() => {navigate(navItem.href)}}>{navItem.label}</Button>
        </Box>
      ))}
    </Stack>
  );
};


const MobileNav = () => {

  const navigate = useNavigate();
  // i18n Transration
  const { t } = useTranslation();

  const NAV_ITEMS: Array<NavItem> = [
    {
      label: t("menu.text.manage_member"),
      href: '/members',
    },
    {
      label: t("menu.text.manage_team"),
      href: '/teams',
    },
    {
      label: t("menu.text.settings"),
      href: '/settings',
    },
    {
      label: t("menu.text.contact"),
      href: '/contact',
    }
  ];

  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Button w='full' colorScheme='teal' variant='ghost' onClick={() => {navigate(navItem.href)}}>{navItem.label}</Button>
        </Box>
      ))}
    </Stack>
  );
};


