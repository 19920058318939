import { useState } from "react";
import { z } from "zod";
import { Box, Button, Heading, Input, Text, Textarea, useToast } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
import { useContactHook } from "../Hook/ContactHook";


/*******************************************************************
 * 設定コンポーネント
 *******************************************************************/
 export const Contact = () => {

  // Hooks ******************************************
  const useContact = useContactHook();
  const toast = useToast();

  // i18n Transration
  const { t } = useTranslation();

  // 初期フォーム ******************************************
  const initialForm = {
    title: "",
    body: "",
  };

  const [contactForm, setContactForm] = useState(initialForm);
  
  const [titleError, setTitleError] = useState<string|null>(null);
  const [bodyError, setBodyError] = useState<string|null>(null);


  // フォームの値更新イベント
  const handleContactFormChanges = (input:string) => (e: { target: { value: string; }; }) => {
    setContactForm({...contactForm, [input] : e.target.value});
  };

  // Eメール更新イベント
  const handleSend = async () => {

    setTitleError(null);
    setBodyError(null);

    const FormValidator = z.object({
      title: z.string()
        .min(1, { message: t("message.text.validation.required_title")! }),
      body: z.string()
        .min(1, { message: t("message.text.validation.required_body")! })
    })

    try {
      FormValidator.parse(contactForm);
      await useContact.sendContactMail(contactForm.title, contactForm.body);

      toast({
        title: t("message.text.action.send_contact_mail_success"),
        status: 'success',
        isClosable: true,
        duration: 5000,
      })

    } catch(err) {
      if (err instanceof z.ZodError) {
        const flatErrorInfo = err.flatten()
        if(flatErrorInfo.fieldErrors["title"]) setTitleError(flatErrorInfo.fieldErrors["title"][0]);
        if(flatErrorInfo.fieldErrors["body"]) setBodyError(flatErrorInfo.fieldErrors["body"][0]);
      }
    }
  }

  return (
    <>
      <Heading m={8} textAlign='left'>{t("screen.text.contact.page_header")}</Heading>

      <Box p={8}>
        <Text fontSize='xl' textAlign='left' mt={4}>{t("screen.text.contact.label_title")}</Text>
        <Input variant='outline' color='gray.600' placeholder={t("input.placeholder.contact.title")!} onChange={handleContactFormChanges('title')} defaultValue={contactForm.title} size='lg' mt={4}/>
        {titleError && <Text fontSize='sm' textAlign='left' color='tomato'>　{titleError}</Text>}

        <Text fontSize='xl' textAlign='left' mt={4}>{t("screen.text.contact.label_body")}</Text>
        <Textarea variant='outline' color='gray.600' placeholder={t("input.placeholder.contact.body")!}  onChange={handleContactFormChanges('body')}  defaultValue={contactForm.body} size='lg' mt={4} resize="vertical" rows={10}/>
        {bodyError && <Text fontSize='sm' textAlign='left' color='tomato'>　{bodyError}</Text>}

        <Box textAlign='right' mt={4} mb={12}>
          <Button onClick={handleSend} color='teal.300' size='lg'>{t("button.text.contact.send_mail")}</Button>
        </Box>

      </Box>

    </>
  );
}
