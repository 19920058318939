import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../State/AuthState";
import { teamsState } from "../State/TeamsState";
import { useNavigate } from "react-router";
import { Team } from "../Model/Team";
import { z } from "zod";
import { useTeamsHook } from "../Hook/TeamsHook";
import { membersState } from "../State/MembersState";
import { useMembersHook } from "../Hook/MembersHook";
import Loading from "../Component/Loading";
import Spacer from "../Component/Spacer";
import CopyToClipboardForm from "../Component/CopyToClipboardForm";
import { teamMembersState } from "../State/TeamMembersState";
import { Button, ButtonGroup, FormControl, FormHelperText, FormLabel, Heading, IconButton, Input, Tab, Table, TableContainer, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Th, Thead, Tooltip, Tr, useToast, Wrap } from "@chakra-ui/react";
import { RepeatIcon } from '@chakra-ui/icons';
import { MdMailOutline } from 'react-icons/md'
import { Member } from "../Model/Member";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";




interface TeamMemberProps {
  member: Member;
}

const TeamMemberRow = (props:TeamMemberProps) => {

  // Hooks ******************************************
  const useMembers = useMembersHook();
  const authUser = useRecoilValue(authState);   // 現在のログインユーザー
  const toast = useToast();
  // i18n Transration
  const { t } = useTranslation();

  // Local State
  const [accessToken, setAccessToken] = useState(props.member.secret?.accessToken || '');

   // アクセストークンの再生成イベント
   const createAccessToken = async () => {

    if (!authUser) return;

    try {
      const token = await useMembers.generateAccessToken(props.member, accessToken);
      setAccessToken(token);

      toast({
        title: t("message.text.action.create_access_token_success"),
        status: 'success',
        duration: 2000,
      })

    } catch(err) {

      toast({
        title: `message.text.action.create_access_token_failed`,
        status: 'error',
        duration: 2000,
      })

    }
  }

   // メール送信イベント
   const sendMail = async () => {

    if (!authUser) return;

    try {
      await useMembers.sendMailForInviteTeam(props.member, accessToken);

      toast({
        title: t('message.text.action.send_invite_mail_success'),
        status: 'success',
        duration: 2000,
      })

    } catch(err) {

      toast({
        title: t('message.text.action.send_invite_mail_failed'),
        status: 'error',
        duration: 2000,
      })

    }
  }


  return (
    <Tr sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <Td>{props.member.name}</Td>
      <Td><CopyToClipboardForm copyText={accessToken}/></Td>
      <Td>
        <ButtonGroup variant='outline' spacing='2'>
          <Tooltip label={t("screen.text.teams.label_regenerate_token")!}>
            <IconButton onClick={createAccessToken} icon={<RepeatIcon />} aria-label={t("screen.text.teams.label_regenerate_token")!}/>
          </Tooltip>
          <Tooltip label={t("screen.text.teams.label_invite_mail")!}>
            <IconButton onClick={sendMail} icon={<MdMailOutline />} aria-label={t("screen.text.teams.label_invite_mail")!}/>
          </Tooltip>
        </ButtonGroup>
      </Td>
    </Tr>
  )

}


/*******************************************************************
 * チーム一覧コンポーネント
 *******************************************************************/
 export const TeamDetail = () => {

  const params = useParams();

  // Hooks ******************************************
  const navigate = useNavigate();
  const useTeams = useTeamsHook();
  const toast = useToast();
  const { t } = useTranslation();

  // Recoil State ******************************************
  const teams = useRecoilValue(teamsState);
  const members = useRecoilValue(membersState);
  const teamMembers = useRecoilValue(teamMembersState);
  const authUser = useRecoilValue(authState);


  const getTeam = () => {
    const team = teams.find(team => team.id === params.teamId);
    return team;
  }


  useEffect(() => {
    if(!authUser) return;
    if(!params.teamId) return;

    useTeams.fetchTeamMembers(authUser, params.teamId);
    // eslint-disable-next-line
  }, [authUser]); 
  

  // チームの更新・削除に関する処理 ******************************************
  const [updaterForm, setUpdaterForm] = useState<Team|null>();
  const [nameForUpdateError, setNameForUpdateError] = useState<string|null>(null);

  useEffect(() => {
    if(!authUser) return;
    if(!params.teamId) return;

    setUpdaterForm(getTeam());
    // eslint-disable-next-line
  }, [authUser]); 


  // 編集用フォームの更新イベント
  const handleUpdaterFormChange = (input:string) => (e: { target: { value: string; }; }) => {
    const updateData = {...updaterForm, ...{[input]: e.target.value}};
    setUpdaterForm(updateData);
  };
  
  // データ更新イベント
  const handleUpdate = async () => {

    setNameForUpdateError(null);

    if (!updaterForm) return;
    if (!authUser) return;

    const FormValidator = z.object({
      name: z.string()
              .min(1, { message: t("message.text.validation.required_team")! }),
    });

    try {
      FormValidator.parse(updaterForm);
      await useTeams.updateTeam(authUser, updaterForm);

      toast({
        title: t("message.text.action.update_team_success"),
        status: 'success',
        duration: 2000,
      })

      await navigate(`/teams`);
    } catch(err) {

      toast({
        title: t("message.text.action.happen_error"),
        status: 'error',
        duration: 2000,
      })

      if (err instanceof z.ZodError) {
        const flatErrorInfo = err.flatten()
        if(flatErrorInfo.fieldErrors["name"]) setNameForUpdateError(flatErrorInfo.fieldErrors["name"][0]);
      }
    }
  }


  if(!authUser) return (<Loading />);

  return (
    <>
      <Heading m={8} textAlign='left'>{getTeam()?.name}</Heading>

      <Tabs colorScheme='black'>
        <TabList>
          <Tab>{t("screen.text.teams.label_joined_member")}</Tab>
          <Tab>{t("screen.text.teams.label_others")}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <TableContainer m={4}>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>{t("screen.text.teams.label_member_name")}</Th>
                    <Th>{t("screen.text.teams.label_access_token")}</Th>
                    <Th w={20}></Th>
                  </Tr>
                </Thead>
                <Tbody>

                {teamMembers
                      .map((teamMember) => members.find((member)=> teamMember.id === member.id))
                      .map((member) => (
                  member ? <TeamMemberRow key={member.id}  member={member} /> : <></>
                ))}

                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>

            <FormControl mb={4} mt={4}>
              <FormLabel>{t("screen.text.teams.label_team_name")}</FormLabel>
              <Input variant='outline' size='lg' color='gray.600' placeholder='Team Pingly' onChange={handleUpdaterFormChange("name")} defaultValue={updaterForm?.name} required/>
              {nameForUpdateError && <FormHelperText color='tomato'>　{nameForUpdateError}</FormHelperText>}
            </FormControl>

            <Wrap w='full' mt={8} mr={8} spacing='8'>
              <Spacer />
              <Button onClick={handleUpdate} color='teal.300'>{t("button.text.update_team")}</Button>
            </Wrap>

          </TabPanel>
        </TabPanels>
      </Tabs>

    </>
  );
}


