import { useTranslation } from "react-i18next";
import React from "react";

export const NotFound = () => {

  // i18n Transration
  const { t } = useTranslation();

  return (
    <>
      <h1>404</h1>
      <h3>{t("screen.text.404_not_found")}</h3>
    </>
  );
}