import { atom, selectorFamily } from 'recoil';
import { Team } from '../Model/Team';

type TeamsState = Team[];

export const teamsState = atom<TeamsState>({
  key: 'teamsState',
  default: [],
});

export const teamSelector = selectorFamily({
  key: 'teamSelector',
  get: (id) => ({get}) => {
    const teams = get(teamsState);
    return teams.find((team) => team.id === id);
  },
})
