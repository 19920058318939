import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { ChakraProvider } from '@chakra-ui/react';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      ja: {
        translation: {
          // 入力項目
          "input.placeholder.name": "名前 *",
          "input.placeholder.email": "Eメール *",
          "input.placeholder.password": "パスワード *",
          "input.placeholder.confirm_password": "確認用パスワード *",
          "input.placeholder.join_team": "参加チーム",
          "input.placeholder.add_team": "追加するチームの名前 *",

          // ボタン
          "button.text.signin": "　　ログイン　　",
          "button.text.signup": "　　新規登録　　",
          "button.text.signout": "ログアウト",
          "button.text.add_member": "登録する",
          "button.text.edit_member": "メンバー情報を修正する",
          "button.text.delete_member": "メンバーを削除する",
          "button.text.work_report": "稼働状況を確認する",
          "button.text.add_team": "登録する",
          "button.text.show_team_detail": "チーム情報の詳細を見る",
          "button.text.delete_team": "チームを削除する",
          "button.text.update_team": "チームを更新する",
          "button.text.update": "更新する",
          "button.text.delete": "削除する",
          "button.text.cancel": "キャンセル",

          // メニュー
          "menu.text.manage_member": "メンバー管理",
          "menu.text.manage_team": "チーム管理",
          "menu.text.settings": "設定",
          "menu.text.contact": "お問い合わせ",

          // バリデーション
          "message.text.validation.required_name": "名前が未入力です",
          "message.text.validation.required_email": "Eメールが未入力です",
          "message.text.validation.required_password": "パスワードが未入力です",
          "message.text.validation.invalid_name": "名前が正しくありません",
          "message.text.validation.invalid_email": "Eメールが正しくありません",
          "message.text.validation.invalid_password": "パスワードが正しくありません",
          "message.text.validation.required_team": "チーム名は必ず入力してください",
          "message.text.validation.required_base_work_hours": "通常勤務時間数は必ず入力してください",
          "message.text.validation.invalid_base_work_hours": "通常勤務時間数は必ず数値で入力してください",
          "message.text.validation.required_longest_work_hours": "最長勤務時間数は必ず入力してください",
          "message.text.validation.invalid_longest_work_hours": "最長勤務時間数は必ず数値で入力してください",
          "message.text.validation.invalid_current_password": "現在のパスワードが正しくありません",
          "message.text.validation.invalid_new_password": "新しいパスワードが正しくありません",
          "message.text.validation.invalid_confirm_password": "確認用パスワードが正しくありません",
          "message.text.validation.required_title": "タイトルを入力してください",
          "message.text.validation.required_body": "詳細を入力してください",

          // 表示メッセージ
          "message.text.action.login_success": "ログインしました",
          "message.text.action.login_failed": "ログインに失敗しました",
          "message.text.action.add_member_success": "メンバーを追加しました",
          "message.text.action.update_member_success": "メンバー情報を更新しました",
          "message.text.action.delete_member_success": "メンバーを削除しました",
          "message.text.action.members_count_over": "メンバー数が登録可能上限数を超えています",
          "message.text.action.happen_error": "エラーが発生しました",
          "message.text.action.create_access_token_success": "トークンを再生成しました",
          "message.text.action.create_access_token_failed": "トークンの再生成に失敗しました",
          "message.text.action.send_invite_mail_success": "メールを送信しました`",
          "message.text.action.send_invite_mail_failed": "メールの送信に失敗しました",
          "message.text.action.add_team_success": "チームを追加しました",
          "message.text.action.update_team_success": "チーム情報を更新しました",
          "message.text.action.delete_team_success": "チームを削除しました",
          "message.text.action.update_mail_success": "メールアドレスを更新しました",
          "message.text.action.update_password_success": "パスワードを更新しました",
          "message.text.action.update_setting_success": "設定を更新しました",
          "message.text.action.send_contact_mail_success": "問い合わせを送信しました。回答はご登録のメールアドレス宛に送付させていただきます",
          "message.text.action.send_contact_mail_failed": "メールの送信に失敗しました",

          // 個別ラベル
          "screen.text.signin.not_exists_account_prefix": "アカウントをお持ちでない方は",
          "screen.text.signin.not_exists_account_link": "こちらから",
          "screen.text.signin.client_download_here": "デスクトップクライアントは以下からダウンロードできます",

          "screen.text.signup.exists_account_prefix": "既にアカウントをお持ちの方は",
          "screen.text.signup.exists_account_link": "こちらから",

          // *** メンバー管理画面 *************************************************************************************************
          //    Title
          "screen.text.members.page_header": "メンバーを管理",
          //    Labels
          "screen.text.members.label_reflesh_status": "ステータス更新",
          "screen.text.members.loggined": "稼働中",
          "screen.text.members.not_loggined": "未稼働",
          "screen.text.members.working": "在席中",
          "screen.text.members.not_working": "離席中",
          "screen.text.members.label_name": "名前",
          "screen.text.members.label_email": "メールアドレス",
          "screen.text.members.label_join_team": "参加チーム",
          "screen.text.members.label_loginned": "ログイン状況",
          "screen.text.members.label_working": "在席状況",
          "screen.text.members.label_work_report": "稼働状況",
          "screen.text.members.label_current_month_work_report": "今月の稼働状況",
          //    Dialogs
          "screen.text.members.confirm_dialog_title": "確認",
          "screen.text.members.confirm_dialog_text_1": "対象のメンバーを削除します。",
          "screen.text.members.confirm_dialog_text_2": "よろしいですか？",


          // *** チーム管理画面 *************************************************************************************************
          //    Title
          "screen.text.teams.page_header": "チームを管理",
          //    Labels
          "screen.text.teams.label_regenerate_token": "アクセストークンを再生成",
          "screen.text.teams.label_invite_mail": "招待メールの送信",
          "screen.text.teams.label_joined_member": "参加メンバー",
          "screen.text.teams.label_others": "その他",
          "screen.text.teams.label_member_name": "名前",
          "screen.text.teams.label_access_token": "アクセストークン",
          "screen.text.teams.label_team_name": "チーム名",
          "screen.text.teams.label_base_work_hours": "通常勤務時間数",
          "screen.text.teams.label_longest_base_hours": "最長勤務時間数",
          //    Dialogs
          "screen.text.teams.manage_dialog_title": "チーム情報",
          "screen.text.teams.confirm_delete_dialog_title": "確認",
          "screen.text.teams.confirm_delete_dialog_text_1": "対象のチームを削除します。",
          "screen.text.teams.confirm_delete_dialog_text_2": "よろしいですか？",


          // *** 設定画面 *************************************************************************************************
          //    Title
          "screen.text.settings.page_header": "アカウント設定",
          //    Labels
          "screen.text.settings.label_email": "メールアドレス",
          "screen.text.settings.label_password": "パスワード",
          "screen.text.settings.label_others": "外部連携",
          "screen.text.settings.label_where_by_token": "Whereby",
          "screen.text.settings.label_where_by_token_description": "をご利用のお客様はAPIキーを登録することでWhereby会議を1:1ミーティングに使用することができます",
          //    Place Holders
          "input.placeholder.settings.email": "メールアドレスを入力してください *",
          "input.placeholder.settings.current_password": "現在のパスワードを入力してください *",
          "input.placeholder.settings.new_password": "新しいパスワードを入力してください *",
          "input.placeholder.settings.confirm_password": "確認用パスワードを入力してください *",
          "input.placeholder.settings.where_by_token": "Where by APIキーを入力してください",
          //    Buttons
          "button.text.settings.update_mail": "メールアドレスを更新する",
          "button.text.settings.update_password": "パスワードを変更する",
          "button.text.settings.update_others": "Whereby API Keyを変更する",

          // *** お問い合わせ画面 *************************************************************************************************
          //    Title
          "screen.text.contact.page_header": "お問い合わせ",
          //    Labels
          "screen.text.contact.label_title": "タイトル",
          "screen.text.contact.label_body": "詳細",
          //    Place Holders
          "input.placeholder.contact.title": "問い合わせのタイトルを入力してください *",
          "input.placeholder.contact.body": "具体的なお問い合わせ内容を入力してください *",
          //    Buttons
          "button.text.contact.send_mail": "問い合わせを送る",
          

          // *** Clipboard copy *************************************************************************************************
          "message.text.action.copied": "コピーしました！",

          // *** 404 No Found *************************************************************************************************
          "screen.text.404_not_found": "お探しのページは見つかりませんでした。",
        }
      },
      en: {
        translation: {
          // 入力項目
          "input.placeholder.name": "Name *",
          "input.placeholder.email": "E-mail *",
          "input.placeholder.password": "Password *",
          "input.placeholder.confirm_password": "Confirm password *",
          "input.placeholder.join_team": "Select Team",
          "input.placeholder.add_team": "Team name *",

          // ボタン
          "button.text.signin": "　　Sign In　　",
          "button.text.signup": "　　Sign Up　　",
          "button.text.signout": "Sign out",
          "button.text.add_member": "Add",
          "button.text.edit_member": "Edit",
          "button.text.delete_member": "Delete",
          "button.text.work_report": "Work Report",
          "button.text.add_team": "Add Team",
          "button.text.show_team_detail": "Show Detail",
          "button.text.delete_team": "Delete",
          "button.text.update_team": "Update",
          "button.text.update": "OK",
          "button.text.delete": "OK",
          "button.text.cancel": "Cancel",

          // メニュー
          "menu.text.manage_member": "Members",
          "menu.text.manage_team": "Teams",
          "menu.text.settings": "Settings",
          "menu.text.contact": "Contact",

          // バリデーション
          "message.text.validation.required_name": "required name",
          "message.text.validation.required_email": "required e-mail",
          "message.text.validation.required_password": "required password",
          "message.text.validation.invalid_name": "invalid name",
          "message.text.validation.invalid_email": "invalid e-mail",
          "message.text.validation.invalid_password": "invalid password",
          "message.text.validation.required_team": "`required team",
          "message.text.validation.required_base_work_hours": "required base work hours",
          "message.text.validation.invalid_base_work_hours": "invalid base work hours",
          "message.text.validation.required_longest_work_hours": "required lomgest work hours",
          "message.text.validation.invalid_longest_work_hours": "invalid longest work hours",
          "message.text.validation.invalid_current_password": "invalid current password",
          "message.text.validation.invalid_new_password": "invalid new password",
          "message.text.validation.invalid_confirm_password": "invalid confirm password",
          "message.text.validation.required_title": "required base work title",
          "message.text.validation.required_body": "required base work detail",
    

          // 表示メッセージ
          "message.text.action.login_success": "logging in.",
          "message.text.action.login_failed": "login failed.",
          "message.text.action.add_member_success": "member added.",
          "message.text.action.update_member_success": "member updated.",
          "message.text.action.delete_member_success": "member deleted.",
          "message.text.action.members_count_over": "Member has exceeded registration limit",
          "message.text.action.happen_error": "error.",
          "message.text.action.create_access_token_success": "token generated.",
          "message.text.action.create_access_token_failed": "token generate failed.",
          "message.text.action.send_invite_mail_success": "invite mail sent.",
          "message.text.action.send_invite_mail_failed": "invite mail send failed.",
          "message.text.action.add_team_success": "add team.",
          "message.text.action.update_team_success": "update team.",
          "message.text.action.delete_team_success": "delete team.",
          "message.text.action.update_mail_success": "E-Mail updated.",
          "message.text.action.update_password_success": "Password updated.",
          "message.text.action.update_setting_success": "Setting updated.",
          "message.text.action.send_contact_mail_success": "contact mail sent. reply to your mail soon.",
          "message.text.action.send_contact_mail_failed": "contact mail send failed.",

          // 個別ラベル
          "screen.text.signin.not_exists_account_prefix": "Don’t have an account？",
          "screen.text.signin.not_exists_account_link": "Sign up.",
          "screen.text.signin.client_download_here": "Desktop client download here.",

          "screen.text.signup.exists_account_prefix": "Already have an account？",
          "screen.text.signup.exists_account_link": "Sign in,",

          // *** メンバー管理画面 *************************************************************************************************
          //    Title
          "screen.text.members.page_header": "Members",
          //    Labels
          "screen.text.members.label_reflesh_status": "Reflesh status",
          "screen.text.members.loggined": "Logging in",
          "screen.text.members.not_loggined": "Not login",
          "screen.text.members.working": "Working",
          "screen.text.members.not_working": "Break",
          "screen.text.members.label_name": "Name",
          "screen.text.members.label_email": "Email",
          "screen.text.members.label_join_team": "Team",
          "screen.text.members.label_logging": "logging",
          "screen.text.members.label_working": "Working",
          "screen.text.members.label_work_report": "Work Report",
          "screen.text.members.label_current_month_work_report": "Current month",
          //    Dialogs
          "screen.text.members.confirm_dialog_title": "Confirm",
          "screen.text.members.confirm_dialog_text_1": "delete select member",
          "screen.text.members.confirm_dialog_text_2": "Sure？",

          // *** チーム管理画面 *************************************************************************************************
          //    Title
          "screen.text.teams.page_header": "Teams",
          //    Labels
          "screen.text.teams.label_regenerate_token": "Reload access token",
          "screen.text.teams.label_invite_mail": "Invite",
          "screen.text.teams.label_joined_member": "Members",
          "screen.text.teams.label_others": "Other",
          "screen.text.teams.label_member_name": "Name",
          "screen.text.teams.label_access_token": "Access Token",
          "screen.text.teams.label_team_name": "Team Name",
          "screen.text.teams.label_base_work_hours": "Base work hours",
          "screen.text.teams.label_longest_work_hours": "Longest work hours",
          //    Dialogs
          "screen.text.teams.manage_dialog_title": "Team info",
          "screen.text.teams.confirm_delete_dialog_title": "`Confirm",
          "screen.text.teams.confirm_delete_dialog_text_1": "delete select team",
          "screen.text.teams.confirm_delete_dialog_text_2": "Sure？",


          // *** 設定画面 *************************************************************************************************
          //    Title
          "screen.text.settings.page_header": "Settings",
          //    Labels
          "screen.text.settings.label_email": "E-mail",
          "screen.text.settings.label_password": "Password",
          "screen.text.settings.label_others": "Others",
          "screen.text.settings.label_where_by_token": "Whereby",
          "screen.text.settings.label_where_by_token_description": "use 1 on 1 meeting. Please add API Key.",
          //    Place Holders
          "input.placeholder.settings.email": "E-mail *",
          "input.placeholder.settings.current_password": "Current password *",
          "input.placeholder.settings.new_password": "New password *",
          "input.placeholder.settings.confirm_password": "Confirm password *",
          "input.placeholder.settings.where_by_token": "Where by API Key",
          //    Buttons
          "button.text.settings.update_mail": "Update E-mail",
          "button.text.settings.update_password": "Update Password",
          "button.text.settings.update_others": "Update Whereby API Key",


          // *** お問い合わせ画面 *************************************************************************************************
          //    Title
          "screen.text.contact.page_header": "Contact",
          //    Labels
          "screen.text.contact.label_title": "Title",
          "screen.text.contact.label_body": "Detail",
          //    Place Holders
          "input.placeholder.contact.title": "Title *",
          "input.placeholder.contact.body": "Detail *",
          //    Buttons
          "button.text.contact.send_mail": "Send",

          // *** Clipboard copy *************************************************************************************************
          "message.text.action.copied": "Copied!",

          // *** 404 No Found ***************************************************************************************************
          "screen.text.404_not_found": "Page not found.",

        }
      }
    },
    lng: "ja", 
    fallbackLng: "ja",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
